<template>
    <div id="jobs" class="jobs-page">
      <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('Jobs')" :pathFrom="`/`" />
      <div class="container">
      <IndexJobs />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Jobs Page',
  components: {
    IndexJobs: defineAsyncComponent( () => import('@/components/Jobs/IndexJobs.vue') ),
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
  },
  }
  </script>



